<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <b-row>
                  <b-col><h6 class="mb-0">Danh sách thẻ</h6></b-col>
                  <b-col class="text-right">
                    <router-link
                        :to="'/card-store/import'"
                        v-if="hasPermission('cardImport')"
                    >
                      <b-button class="btn btn-success font-weight-bold"
                      ><i class="fa fa-plus"></i> Import thẻ
                      </b-button>
                    </router-link>
                  </b-col>
                </b-row>
              </template>
              <div class="filter-transaction">
                <div class="row mb-6">
                  <div class="col-lg-3 mb-lg-0 mb-6">
                    <label>Serial:</label>
                    <input
                      type="text"
                      class="form-control datatable-input"
                      v-model="filter.card_serial"
                      placeholder="Nhập mã serial"
                    />
                  </div>
                  <div class="col-lg-3 mb-lg-0 mb-6">
                    <label>Mã order nhập:</label>
                    <input
                        type="text"
                        class="form-control datatable-input"
                        v-model="filter.order_number"
                        placeholder="Nhập mã order nhập"
                    />
                  </div>
                  <div class="col-lg-3 mb-lg-0 mb-6">
                    <b-form-group label="Loại thẻ">
                      <multiselect
                          v-model="filter.card_type"
                          :options="searchData.cardTypes"
                          :multiple="true"
                          label="text"
                          track-by="value"
                          placeholder="Chọn loại thẻ"
                      ></multiselect>
                    </b-form-group>
                  </div>
              </div>
              <div class="row mb-6">
                <div class="col-lg-3 mb-lg-0 mb-6">
                  <b-form-group label="Trạng thái">
                    <b-form-select v-model="filter.card_status" :options="searchData.cardStatus"></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-lg-3 mb-lg-0 mb-6">
                  <label>Ngày hết hạn:</label>
                  <date-picker format="DD/MM/YYYY" v-model="filter.expired_date" type="date" range placeholder="Chọn khoảng ngày"></date-picker>
                </div>
                <div class="col-lg-3 mb-lg-0 mb-6">
                  <label>Đối tác:</label>
                  <b-form-select v-model="filter.card_provider">
                    <option value="">Chọn đối tác</option>
                    <option value="funtap">Funtap</option>
                    <option value="imedia">iMedia</option>
                    <option value="epay">Epay</option>
                    <option value="coda">Coda</option>
                  </b-form-select>
                </div>
                <div class="col-lg-3 mb-lg-0 mb-6">
                  <label>Loại dịch vụ:</label>
                  <b-form-select v-model="filter.card_info">
                    <option value="">Chọn dịch vụ</option>
                    <option value="1">Thẻ điện thoại</option>
                    <option value="2">Thẻ data 3G/4G</option>
                    <option value="3">Thẻ game</option>
                    <option value="4">Thẻ dịch vụ</option>
                  </b-form-select>
                </div>
              </div>
                <div class="row mb-6">
                  <div class="col-lg-3 mb-lg-0 mb-6">
                    <label>Ngày mua:</label>
                    <date-picker format="DD/MM/YYYY" v-model="filter.created_at" type="date" range placeholder="Chọn khoảng ngày"></date-picker>
                  </div>
                  <div class="col-lg-3 mb-lg-0 mb-6">
                    <label>Ngày bán:</label>
                    <date-picker format="DD/MM/YYYY" v-model="filter.date_usage" type="date" range placeholder="Chọn khoảng ngày"></date-picker>
                  </div>
                  <div class="col-lg-3 mb-lg-0 mb-6">
                    <b-form-group label="Mệnh giá">
                      <multiselect
                          v-model="filter.denomination"
                          :options="searchData.denomination"
                          :multiple="true"
                          label="text"
                          track-by="value"
                          placeholder="Chọn mệnh giá"
                      ></multiselect>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="row mt-8 mb-4">
                <div class="col-lg-12">
                  <div class="d-flex justify-content-between">
                    <div>
                      <button
                          class="btn btn-primary btn-primary--icon"
                          @click="search()"
                      >
                      <span>
                        <i class="la la-search"></i>
                        <span>Tìm kiếm</span>
                      </span>
                      </button>
                      <button
                          class="btn btn-default btn-default--icon ml-2"
                          @click="clearFilter()"
                      >
                        <span>Đặt lại</span>
                      </button>
                    </div>
                    <button
                        class="btn btn-success"
                        v-if="hasPermission('cardExport')"
                        @click="$bvModal.show('export-desc')"
                    >
                      <i class="la la-cloud-download"></i>
                      <span>Xuất Excel</span>
                    </button>
                  </div>
                </div>
              </div>
              <b-table
                  table-class="datatable-table"
                  hover
                  tbody-tr-class="datatable-row"
                  thead-tr-class="datatable-row"
                  thead-class="datatable-head"
                  details-td-class="datatable-cell"
                  :items="items"
                  :fields="fields"
              >
                <template #cell(card_provider)="data">
                  {{data.item.provider ? data.item.provider.card_provider : data.item.card_type}}
                </template>
                <template #cell(card_info)="data">
                  {{getCardInfo(data.item)}}
                </template>
                <template #cell(order_number)="data">
                  {{data.item.provider ? data.item.provider.order_number : ''}}
                </template>
                <template #cell(denomination)="data">
                  {{numberFormat(data.item.denomination)}}
                </template>
                <template #cell(card_status)="data">
                  {{getStatus(data.item)}}
                </template>

                <template #cell(status)="data">
                  <span class="label font-weight-bold label-lg label-inline label-light-info" v-if="data.item.status == 0"> pending </span>
                  <span class="label font-weight-bold label-lg label-inline label-light-success" v-else-if="data.item.status == 1"> success </span>
                </template>
              </b-table>
              <div class="pagination-info mt-2 font-weight-bold">
                Hiển thị {{ currentPage }} / {{ paginate.totalPage }} trang của {{ paginate.total }} mục dữ liệu
              </div>
              <div class="mt-3">
                <b-pagination-nav
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :number-of-pages="paginate.totalPage"
                    use-router
                    first-number
                    last-number
                    align="right"
                ></b-pagination-nav>
              </div>
            </b-card>
          </b-card-group>
          <b-modal id="export-desc" title="Yêu cầu xuất dữ liệu">
            <br />
            <div class="d-block text-center">
              <b-form-textarea
                  placeholder="Nhập mô tả yêu cầu xuất dữ liệu ..."
                  v-model="filter.exportDesc"
                  rows="3"
              ></b-form-textarea>
            </div>
            <template #modal-footer="{ ok, cancel }">
              <b-button size="sm" variant="danger" @click="cancel()"> Hủy </b-button>
              <b-button size="sm" variant="success" @click="exportData()">
                Xuất
              </b-button>
            </template>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Vue from 'vue';
import RepositoryFactory from '@/core/repositories/repositoryFactory';
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import moment from "moment";
import da from "vue2-datepicker/locale/es/da";
import permissionMiddleware from "@/core/mixins/permissionMiddleware";

DatePicker.locale('vi')

Vue.component('multiselect', Multiselect);

const CardStoreRepository = RepositoryFactory.get('cardStore');

const TYPE_CARD_ELSA = 'elsa';
const TYPE_CARD_RAZER = 'razer';
const TYPE_CARD_IQIYI = 'iqiyi';
const TYPE_CARD_WETV = 'wetv';
const TYPE_CARD_FUNCARD = 'funcard';
const TYPE_CARD_VIETTEL = 'viettel';
const TYPE_CARD_MOBI    = 'mobifone';
const TYPE_CARD_VINA    = 'vinaphone';
const TYPE_CARD_VNMOBILE = 'vietnamobile';
const TYPE_CARD_GMOBILE = 'beeline';
const TYPE_CARD_GMOBILE2 = 'gmobile';
const TYPE_CARD_FPT = 'fpt'; //Gate
const TYPE_CARD_VTC = 'vtc'; //vcoin
const TYPE_CARD_KULL = 'kull';
const TYPE_CARD_GARENA = 'garena';
const TYPE_CARD_ZING = 'zing'; //Zing
const TYPE_CARD_ONCASH = 'oncashdn';
const TYPE_CARD_BIT = 'bitcard';
const TYPE_CARD_APPOTA = 'appota';
const TYPE_CARD_SOHA = 'sohacoin';
const TYPE_CARD_DATA = 'mobinetwork';
const TYPE_CARD_GOSU = 'gosu';
const TYPE_CARD_SCOIN = 'scoin';
const TYPE_CARD_CODA = 'coda';
// const TYPE_CARD_EPAY = 'epay';
// const TYPE_CARD_IMEDIA = 'imedia';
// const TYPE_PROVIDER_FUNCAR = 'funcard';

const CARD_TYPE_TELCO = [TYPE_CARD_MOBI, TYPE_CARD_VINA, TYPE_CARD_GMOBILE2, TYPE_CARD_GMOBILE, TYPE_CARD_VNMOBILE, TYPE_CARD_VIETTEL];
const CARD_TYPE_DATA = [TYPE_CARD_DATA];
const CARD_TYPE_GAME = [TYPE_CARD_APPOTA, TYPE_CARD_FUNCARD, TYPE_CARD_FPT, TYPE_CARD_VTC, TYPE_CARD_GARENA, TYPE_CARD_ZING, TYPE_CARD_ONCASH, TYPE_CARD_BIT, TYPE_CARD_RAZER, TYPE_CARD_SOHA, TYPE_CARD_SCOIN, TYPE_CARD_GOSU, TYPE_CARD_KULL];
const CARD_TYPE_SERVICE = [TYPE_CARD_IQIYI, TYPE_CARD_ELSA, TYPE_CARD_WETV, TYPE_CARD_CODA]

export default {
  computed: {
    da() {
      return da
    }
  },
  components: {
    Multiselect,
    DatePicker
  },
  mixins: [Common],
  data() {
    return {
      filter: {
        card_type: [],
        card_status: null,
        expired_date: [],
        card_provider: null,
        card_info: null,
        created_at: [],
        date_usage: [],
        denomination: [],
        card_serial: null,
        order_number: null,
      },
      fields: [
        { key: 'id', label: '#' },
        { key: 'card_provider', label: 'Nhà cung cấp' },
        { key: 'card_type', label: 'Sản phẩm' },
        { key: 'card_info', label: 'Loại dịch vụ' },
        { key: 'order_number', label: 'Mã Order nhập' },
        { key: 'card_serial', label: 'Serial' },
        { key: 'denomination', label: 'Mệnh giá' },
        { key: 'expired_date', label: 'Hạn sử dụng' },
        { key: 'card_status', label: 'Trạng thái' },
        { key: 'created_at', label: 'Ngày nhập' },
        { key: 'date_usage', label: 'Ngày bán' }
      ],
      items: [],
      currentPage: 1,
      paginate: {
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0
      },
      searchData: {
        cardTypes: [
          { text: 'Viettel', value: 'viettel' },
          { text: 'Vinaphone', value: 'vinaphone' },
          { text: 'Mobifone', value: 'mobifone' },
          { text: 'Beeline', value: 'beeline' },
          { text: 'Vietnamobile', value: 'vietnamobile' },
          { text: 'Funcard', value: 'funcard' },
          { text: 'Bitcard', value: 'bitcard' },
          { text: 'Appota', value: 'appota' },
          { text: 'Garena', value: 'garena' },
          { text: 'VTC', value: 'vtc' },
          { text: 'Gate', value: 'fpt' },
          { text: 'Zing', value: 'zing' },
          { text: 'OnCash', value: 'oncashdn' },
          { text: 'iQiyi', value: 'iqiyi' },
          { text: 'WeTv', value: 'wetv' },
          { text: 'Razer', value: 'razer' },
          { text: 'Elsa', value: 'elsa' },
          { text: 'Steam', value: 'steam' },
          { text: 'Tinder', value: 'tinder' },
          { text: 'Mincraft', value: 'mincraft' },
          { text: 'Battlenet', value: 'battlenet' },
          { text: 'Sohacoin', value: 'sohacoin' },
          { text: 'Scoin', value: 'scoin' },
          { text: 'Gosu', value: 'gosu' },
          { text: 'kull', value: 'kull' }
        ],
        cardStatus: [
          { text: 'ALL', value: null},
          { text: 'AVAILABLE', value: '2' },
          { text: 'EXPIRED', value: '0' },
          { text: 'SOLD', value: '1' },
          { text: 'LOCK', value: '-1' }
        ],
        denomination: [
          { text: '10.000', value: 10000 },
          { text: '11.500', value: 11500 },
          { text: '20.000', value: 20000 },
          { text: '23.000', value: 23000 },
          { text: '25.000', value: 25000 },
          { text: '30.000', value: 30000 },
          { text: '39.000', value: 39000 },
          { text: '50.000', value: 50000 },
          { text: '57.500', value: 57500 },
          { text: '100.000', value: 100000 },
          { text: '130.000', value: 130000 },
          { text: '129.000', value: 129000 },
          { text: '329.000', value: 329000 },
          { text: '589.000', value: 589000 },
          { text: '805.000', value: 805000 },
          { text: '86.250', value: 86250 },
          { text: '115.000', value: 115000 },
          { text: '200.000', value: 200000 },
          { text: '230.000', value: 230000 },
          { text: '250.000', value: 250000 },
          { text: '500.000', value: 500000 },
          { text: '575.000', value: 575000 },
          { text: '1.000.000', value: 1000000 },
          { text: '1.250.000', value: 1250000 },
          { text: '1.995.000', value: 1995000 },
          { text: '2.000.000', value: 2000000 }
        ],
        isSearch: false,
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Danh sách thẻ', route: 'list' }
    ]);

    this.updateFiltersFromUrl()

    this.getList();
  },
  methods: {
    clearFilter(){
      this.filter = {
        card_type: [],
        card_status: null,
        expired_date: [],
        card_provider: null,
        card_info: null,
        created_at: [],
        date_usage: [],
        denomination: [],
        card_serial: null,
        order_number: null,
      };
      this.getList();
    },
    async search() {
      this.currentPage = 1;

      let params = { ...this.filter, page: this.currentPage };

      if (this.filter.card_type != null && this.filter.card_type.length != 0) {
        params.card_type = JSON.stringify(this.listValue(this.filter.card_type))
      } else {
        params.card_type = null
      }
      if (this.filter.denomination != null && this.filter.denomination.length != 0) {
        params.denomination = JSON.stringify(this.listValue(this.filter.denomination))
      } else {
        params.denomination = null
      }
      if (this.filter.expired_date[0] && this.filter.expired_date.length > 0) {
        params.expired_date = moment(this.filter.expired_date[0]).format("DD-MM-YYYY") + ' - ' + moment(this.filter.expired_date[1]).format("DD-MM-YYYY");
      } else {
        params.expired_date = null
      }
      if (this.filter.created_at[0] && this.filter.created_at.length > 0) {
        params.created_at = moment(this.filter.created_at[0]).format("DD-MM-YYYY") + ' - ' + moment(this.filter.created_at[1]).format("DD-MM-YYYY");
      } else {
        params.created_at = null;
      }
      if (this.filter.date_usage[0] && this.filter.date_usage.length > 0) {
        params.date_usage = moment(this.filter.date_usage[0]).format("DD-MM-YYYY") + ' - ' + moment(this.filter.date_usage[1]).format("DD-MM-YYYY");
      } else {
        params.date_usage = null;
      }

      try {
        this.$bus.$emit('show-loading', true);
        let request = await CardStoreRepository.listCard(params);
        this.$bus.$emit('show-loading', false);
        let response = request.data;
        if (response.error_code === 0) {
          response.data.data.forEach(value => {
            value['detail'] = '/#/card-store/list?hash_card=' + value.hash_card;
          });
          this.items = response.data.data;
          this.paginate.total = response.data.total;
          this.paginate.totalPage = response.data.last_page;
          this.searchData.isSearch = true;
        } else {
          alert(response.message);
        }
      } catch (e) {
        this.$bus.$emit('show-loading', false);
      }
    },
    async getList() {
      let params = { ...this.filter, page: this.currentPage };

      if (this.filter.card_type != null && this.filter.card_type.length != 0) {
        params.card_type = JSON.stringify(this.listValue(this.filter.card_type))
      } else {
        params.card_type = null
      }
      if (this.filter.denomination != null && this.filter.denomination.length != 0) {
        params.denomination = JSON.stringify(this.listValue(this.filter.denomination))
      } else {
        params.denomination = null
      }
      if (this.filter.expired_date[0] && this.filter.expired_date.length > 0) {
        params.expired_date = moment(this.filter.expired_date[0]).format("DD-MM-YYYY") + ' - ' + moment(this.filter.expired_date[1]).format("DD-MM-YYYY");
      } else {
        params.expired_date = null
      }
      if (this.filter.created_at[0] && this.filter.created_at.length > 0) {
        params.created_at = moment(this.filter.created_at[0]).format("DD-MM-YYYY") + ' - ' + moment(this.filter.created_at[1]).format("DD-MM-YYYY");
      } else {
        params.created_at = null;
      }
      if (this.filter.date_usage[0] && this.filter.date_usage.length > 0) {
        params.date_usage = moment(this.filter.date_usage[0]).format("DD-MM-YYYY") + ' - ' + moment(this.filter.date_usage[1]).format("DD-MM-YYYY");
      } else {
        params.date_usage = null;
      }

      try {
        this.$bus.$emit('show-loading', true);
        let request = await CardStoreRepository.listCard(params);
        this.$bus.$emit('show-loading', false);
        let response = request.data;
        if (response.error_code === 0) {
          response.data.data.forEach(value => {
            value['detail'] = '/#/card-store/list?hash_card=' + value.hash_card;
          });
          this.items = response.data.data;
          this.paginate.total = response.data.total;
          this.paginate.totalPage = response.data.last_page;
        }
      } catch (e) {
        this.$bus.$emit('show-loading', false);
      }
    },
    listValue($arr) {
      let newArr = [];
      $arr.forEach( (item) => {
        newArr.push(item.value)
      })
      return newArr;
    },
    getStatus(item) {
      if (item.status == 0 && moment().isBefore(moment(item.expired_date))) {
        return "Available";
      } else if (item.status == 0 && moment().isAfter(moment(item.expired_date))) {
        return "Expired";
      } else if (item.status == 1) {
        return "Sold";
      } else {
        return "Lock";
      }
    },
    updateFiltersFromUrl() {
      const urlExpiredDate = this.$route.query.expired_date;
      if (urlExpiredDate) {
        // Split the date range and set the filter.expired_date
        const dates = urlExpiredDate.split(' - ');
        this.filter.expired_date = dates.map(date => moment(date, "DD-MM-YYYY").toDate());
      }

      const urlDenomination = this.$route.query.denomination;
      if (urlDenomination) {
        const values = JSON.parse("[" + urlDenomination + "]");
        this.filter.denomination = this.searchData.denomination.filter(d => values.includes(d.value));
      }

      const urlCardType = this.$route.query.card_type;
      if (urlCardType) {
        const values = JSON.parse("[" + '"' + urlCardType + '"' + "]");
        this.filter.card_type = this.searchData.cardTypes.filter(d => values.includes(d.value));
      }

    },
    createLinkPage(pageNum) {
      return `?page=${pageNum}`;
    },
    getCardInfo(item) {
      if (CARD_TYPE_TELCO.includes(item.card_type)) {
        return "Thẻ điện thoại"
      }

      if (CARD_TYPE_DATA.includes(item.card_type)) {
        return "Thẻ 3G/4G"
      }

      if (CARD_TYPE_GAME.includes(item.card_type)) {
        return "Thẻ game"
      }
      if(CARD_TYPE_SERVICE.includes(item.card_type)) {
        return "Thẻ dịch vụ"
      }

      return "";

    },
    exportData() {
      let params = { ...this.filter };
      if (this.filter.exportDesc != null && this.filter.exportDesc != "") {
        params.export_desc = this.filter.exportDesc;
      }
      if (this.filter.card_type != null && this.filter.card_type.length != 0) {
        params.card_type = JSON.stringify(this.listValue(this.filter.card_type))
      } else {
        params.card_type = null
      }
      if (this.filter.card_status != null && this.filter.card_status.length != 0) {
        params.card_status = JSON.stringify(this.listValue(this.filter.card_status))
      } else {
        params.card_status = null
      }
      if (this.filter.denomination != null && this.filter.denomination.length != 0) {
        params.denomination = JSON.stringify(this.listValue(this.filter.denomination))
      } else {
        params.denomination = null
      }
      if (this.filter.expired_date[0] && this.filter.expired_date.length > 0) {
        params.expired_date = moment(this.filter.expired_date[0]).format("DD-MM-YYYY") + ' - ' + moment(this.filter.expired_date[1]).format("DD-MM-YYYY");
      } else {
        params.expired_date = null
      }
      if (this.filter.created_at[0] && this.filter.created_at.length > 0) {
        params.created_at = moment(this.filter.created_at[0]).format("DD-MM-YYYY") + ' - ' + moment(this.filter.created_at[1]).format("DD-MM-YYYY");
      } else {
        params.created_at = null;
      }
      if (this.filter.date_usage[0] && this.filter.date_usage.length > 0) {
        params.date_usage = moment(this.filter.date_usage[0]).format("DD-MM-YYYY") + ' - ' + moment(this.filter.date_usage[1]).format("DD-MM-YYYY");
      } else {
        params.date_usage = null;
      }
      this.$bvModal.hide('export-desc');
      this.$bus.$emit("show-loading", true);
      this.filter.exportDesc = null;
      try {
        CardStoreRepository.exportCard(params)
            .then((response) => {
              if (response.data.error_code) {
                this.notifyAlert("warn", response.data.message);
              } else {
                this.notifyAlert(
                    "success",
                    "Tạo tiến trình thành công. Vui lòng chờ"
                );
              }
              this.$bus.$emit("show-loading", false);
            })
            .catch(() => {
              this.notifyAlert("warn", "Có lỗi xảy ra");
            });
      } catch (e) {
        this.$bus.$emit('show-loading', false);
      }
    },
    hasPermission(toName) {
      return permissionMiddleware(toName);
    },
  },
  watch: {
    '$route.query.page': {
      immediate: true,
      handler(page) {
        this.currentPage = Number(page) || 1;
      }
    },
    currentPage(newPage) {
      if (newPage !== Number(this.$route.query.page)) {
        this.$router.push({ query: { ...this.$route.query, page: newPage } });
      }
      this.getList();
    }
  }
};
</script>
